import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
    custommenuData,
    custommenuSelector,
} from "../store/reducer/custommenu";
import { assestURL } from "../services/config";
import {
    custommenuitemData,
    Addcustommenuitem,
    Updatecustommenuitem,
    Deletecustommenuitem,
    custommenuitemSelector,
    clearsLoadingDatas,
    clearsCMIAddLoading,
    clearsCMIUpdateLoading,
    clearsCMIDeleteLoading,
    clearData,
    clearErrormsg
} from "../store/reducer/custommenuitem";
import { API_STATUS } from "../utils/constants";
import {
    custommenuitemFields
} from "./fields/custommenuitemform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ErrorSharp } from "@mui/icons-material";

function CustomMenuItem() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { custommenuDatas, custommenuCount } = useSelector(custommenuSelector);
    const { custommenuitemDatas, custommenuitemCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(custommenuitemSelector);
    const resetForm = custommenuitemFields;
    const [formFields, setFormFields] = useState(custommenuitemFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("desc");
    let [searchQuery, setSearchQuery] = useState("");
    const [fileerror, setfileerror] = useState(false);
    //const formDatas = _.map()

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        custommenuitemDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        custommenuitemDispatch();
    };

    const FieldChange = (e, index) => {
        if (formFields[index].name == "price" || formFields[index].name == "weight") {
            const re = /^\d*\.?\d*$/
            if (!e.target.value.match(re) && e.target.value != "") {
                return false;
            }
        }

        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value)
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
            console.log(file);
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setfileUpload(binaryStr);
                setfilePath(file.path);
                setfileerror(false);
                //console.log(binaryStr)
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const thumbs = files.map(file => (
        <div className="dropzone_thum_img" key={file.name}>
            <div className="dropzone_thumb">
                <img
                    src={file.preview}
                    className="dropzone_img"
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: false
    })
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{e.message}</span>
            ))}
        </div>
    ));

    const submitCustomMenuItem = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
                return false;
            }
            //const postData = new FormData();
            const datas = formFields.map((data) => {
                if (data?.name === "status" || data?.name === 'custom_menu_id') {
                    let val = data.value;
                    console.log(val)
                    data.value = val[0].id;
                }
                return arr[data?.name] = data.value;
            });
            arr.image = fileUpload;
            arr.imgpath = filePath;

            const postData = arr;
            console.log(postData, "postData");
            //return false;
            //const postData = arr;
            if (updateID == "")
                dispatch(Addcustommenuitem({ postData }));
            else {
                postData.id = updateID;
                if (arr.status == 0) {
                    MySwal.fire({
                        title: 'Are You Sure to Inactive Custom Menu Item?',
                        html: 'Deactiviting Custom Menu Item will delete products custom items',
                        icon: 'warning',
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonText: 'Ok',
                        cancelButtonText:
                            'Cancel',
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            dispatch(Updatecustommenuitem({ postData, updateID }));
                        }
                    })
                } else {
                    dispatch(Updatecustommenuitem({ postData, updateID }));
                }

            }
            setshowAdd(false);
        } else {
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
            }
            setShowvalidator(true);
            console.log("test");
        }
    };

    const editCustomMenuItem = (row) => {
        window.scrollTo(0, 0)
        setUpdateID(row.id);
        console.log(row.name)
        let data = formFields.map((data, index) => {
            //console.log(_.filter(statusDropdown, { id: row.status }));
            if (data.name === "name")
                data.value = row.name;
            if (data.name === "description")
                data.value = row.description;
            if (data.name === "code")
                data.value = row.code;
            if (data.name === "price")
                data.value = row.price;
            if (data.name === "weight")
                data.value = row.weight;
            if (data.name === "status") {
                let set_status = _.filter(statusDropdown, { id: row.status });
                console.log(set_status[0]);
                data.value = set_status;
            }
            if (data.name === "custom_menu_id") {
                let set_status = _.filter(categoryOptions, { id: row.custom_menu_id });
                console.log(set_status[0]);
                data.value = set_status;
            }
            if (data.name === "image") {
                data.value = assestURL + row.image;
            }

            return data;
        });
        console.log(data, "formfields");
        setFormFields(data)
        setshowAdd(true);
    };



    const deleteCustomMenuItem = (row) => {
        setshowAdd(false);
        //setUpdateID(row.id);
        const deleteID = row.id;
        MySwal.fire({
            title: 'Are You Sure to Delete Custom Menu Item?',
            html: 'Deleting Custom Menu Item will delete products custom items',
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            cancelButtonText:
                'Cancel',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(Deletecustommenuitem({ deleteID }));
            }
        })

    };

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
        })
    };

    const custommenuitemDispatch = () => {
        dispatch(custommenuitemData({
            query: searchQuery,
            page: page + 1,
            limit: rowsPerPage,
            sortby: sortBy,
            order: order,
        }));
    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        custommenuitemDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        custommenuitemDispatch();
    };

    useEffect(() => {
        dispatch(clearData());
        dispatch(custommenuData({}));
        custommenuitemDispatch();
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (custommenuDatas && categoryOptions.length == 0) {
            let catdata = [];
            custommenuDatas.map((data) => {
                return catdata.push({ id: data.id, name: data.name })
            });
            setCategoryOptions(catdata)
            console.log(custommenuDatas, "catdata")
        }
    }, [custommenuDatas])

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearsCMIDeleteLoading());
            custommenuitemDispatch();
        }
        if (addloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearsCMIAddLoading());
            custommenuitemDispatch();
        }
        if (updateloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearsCMIUpdateLoading());
            resetFormFields();
            custommenuitemDispatch();
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            dispatch(clearErrormsg())

        }

    }, [deleteloading, addloading, updateloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Custom Menu Items"
                    Breadcrumb={[{ name: "Custom Menu Items" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Custom Menu Items</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-7">
                                            {(!showAdd) ?
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder="Search Custom Menu Items" />
                                                : ''}
                                        </div>
                                        <div className="col-md-5">
                                            <button type="button" className="btn btn-primary mr-1  float-right" onClick={(e) => {
                                                setshowAdd(!showAdd);
                                                console.log(resetForm, 'categoryFields')
                                                resetFormFields()
                                            }}>
                                                {(showAdd) ? "Cancel" : "Add Custom Item"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={(showAdd) ? "body" : "body hide"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.type}
                                                                    required=""
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className=""
                                                                            options={(fields?.options == "status") ? statusDropdown : (fields?.options == "category" && categoryOptions.length > 0) ? categoryOptions : []}
                                                                            values={(fields.value) ? fields?.value : []}
                                                                            disabled={false}
                                                                            placeholder={fields?.placeHolder}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={fields?.labelField}
                                                                            valueField={fields?.valueField}
                                                                            dropdownHeight="300px"
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        />
                                                                        : (fields?.input == "file") ?
                                                                            <section className="row container">
                                                                                <div className="col-md-8">
                                                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                                                        <input {...getInputProps()} />
                                                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                    </div></div>
                                                                                <div className="col-md-4">
                                                                                    <div className="thumbcontainer">{(thumbs && thumbs.length > 0 ? thumbs : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                        <div className="dropzone_thumb">
                                                                                            <img
                                                                                                src={fields?.value}
                                                                                                className="dropzone_img"
                                                                                            />
                                                                                        </div>
                                                                                    </div> : '')}</div>
                                                                                </div>
                                                                                {fileRejectionItems}
                                                                                <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                            </section>
                                                                            : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitCustomMenuItem(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "custom_menu_id" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("custom_menu_id");
                                                    }}
                                                >
                                                    Custom Menu
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("name");
                                                    }}
                                                >
                                                    Item
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "code" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("code");
                                                    }}
                                                >
                                                    Code
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "weight" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("weight");
                                                    }}
                                                >
                                                    Weight
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "price" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("price");
                                                    }}
                                                >
                                                    Price
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("status");
                                                    }}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                                Sorting No.
                                            </StyledTableCell> */}
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            custommenuitemDatas && custommenuitemDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row?.custom_menus?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row?.code}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.weight}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.price}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status === 1
                                                            ? "Active"
                                                            : "In-active"}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="center">

                                                    </StyledTableCell> */}
                                                    <StyledTableCell align="">
                                                        <a href="javascript:void(0);" onClick={(e) => { editCustomMenuItem(row) }}><i className="icon-pencil text-info p-2"></i></a>
                                                        <a href="javascript:void(0);" onClick={(e) => { deleteCustomMenuItem(row) }}><i className="icon-trash text-danger p-2"></i></a>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={custommenuitemCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomMenuItem;
