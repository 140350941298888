import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { TimePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import {
    posData,
    UpdatePos,
    posSelector,
    clearLoadingDatas,
    clearPosAddLoading,
    clearPosUpdateLoading,
    clearPosDeleteLoading,
    clearData,
    clearErrormsg
} from "../../store/reducer/pos";
import { API_STATUS } from "../../utils/constants";
import {
    posFields
} from "../fields/posform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL } from "../../services/config";

function POS() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { posDatas, posCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(posSelector);
    const resetForm = posFields;
    const [formFields, setFormFields] = useState(posFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("desc");
    let [searchQuery, setSearchQuery] = useState("");
    const format = 'hh:mm A';
    //const formDatas = _.map()

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        posDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        posDispatch();
    };

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value)
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    const CheckOrRadioChange = (value, index) => {
        formFields[index].value = value;
        setFormFields([...formFields]);
    }

    const onChangeTime = (value, time, index, name) => {
        console.log(value, time, "test");
        if (name == "end_time") {
            let cval = new Date(value);
            let getdata = _.filter(formFields, { name: "start_time" })
            console.log(value, getdata[0].tvalue);
            if (getdata[0].tvalue > value) {
                return false;
            }
        }
        if (name == "start_time") {
            let getdata = _.filter(formFields, { name: "end_time" })
            console.log(value, getdata[0].tvalue);
            if (getdata[0].tvalue < value) {
                return false;
            }
        }
        formFields[index].value = time;
        formFields[index].tvalue = value;
        setFormFields([...formFields]);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
            console.log(file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setfileUpload(binaryStr);
                setfilePath(file.path);
                setfileerror(false);
                //console.log(binaryStr)
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const thumbs = files.map(file => (
        <div className="dropzone_thum_img" key={file.name}>
            <div className="dropzone_thumb">
                <img
                    src={file.preview}
                    className="dropzone_img"
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: false
    })


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{e.message}</span>
            ))}
        </div>
    ));
    const submitPos = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
                return false;
            }
            //const postData = new FormData();
            const datas = formFields.map((data) => {
                return arr[data?.name] = data.value;
            });
            arr.id = updateID;
            const postData = arr;
            console.log(postData, "postData");
            //return false;
            //const postData = arr;
            dispatch(UpdatePos({ postData, updateID }));

            setshowAdd(false);
        } else {
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
            }
            setShowvalidator(true);
            console.log("test");
        }
    };

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
        })
    };

    const editPos = (row) => {
        window.scrollTo(0, 0)
        setshowAdd(true);
        setUpdateID(row.id);
        console.log(row.name)
        let data = formFields.map((data, index) => {
            //console.log(_.filter(statusDropdown, { id: row.status }));
            if (data.name === "sch_day")
                data.value = row.sch_day;
            if (data.name === "start_time") {
                data.value = row.start_time;
                data.tvalue = dayjs(row.start_time, format);
            } if (data.name === "end_time") {
                data.value = row.end_time;
                data.tvalue = dayjs(row.end_time, format);
            } if (data.name === "message")
                data.value = row.message;
            if (data.name === "schedule")
                data.value = row.schedule;

            return data;
        });
        console.log(data, "formfields");
        setFormFields(data)
        setfileerror(false);
    };

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        posDispatch();
    };

    const posDispatch = () => {
        dispatch(
            posData({})
        );
    };

    useEffect(() => {
        dispatch(clearData())
        posDispatch()
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        console.log(posDatas, "posDatas")
    }, [posDatas])

    /* useEffect(() => {
        console.log(posDatas, "formFields")
        if (posDatas && typeof (posDatas[0].id) !== "undefined") {
            console.log(posDatas, "editDatas in")
            const row = posDatas[0];
            setUpdateID(row.id);
            let chosen_data = []
            let data = formFields.map((data, index) => {
                //console.log(_.filter(statusDropdown, { id: row.status }));
                if (data.name === "start_time") {
                    data.value = row.start_time;
                    data.tvalue = dayjs(row.start_time, format);
                } if (data.name === "end_time") {
                    data.value = row.end_time;
                    data.tvalue = dayjs(row.end_time, format);
                } if (data.name === "message")
                    data.value = row.message;
                if (data.name === "schedule")
                    data.value = row.schedule;
                return data;
            })
            setFormFields(data);
        }
    }, [posDatas]) */

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearPosDeleteLoading())
            posDispatch()
        }
        if (addloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearPosAddLoading())
            posDispatch()
        }
        if (updateloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearPosUpdateLoading())
            resetFormFields();
            posDispatch()
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            dispatch(clearErrormsg())

        }
    }, [deleteloading, addloading, updateloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="POS & Schedule"
                    Breadcrumb={[{ name: "POS & Schedule" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>POS & Schedule</h2>
                                    </div>
                                </div>
                            </div>
                            <div className={(showAdd) ? "body" : "body hide"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <div className={(fields?.input == "label") ? "col-md-12" : "col-md-6"}>
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.type}
                                                                    required=""
                                                                    readOnly={fields?.readonly}
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={statusDropdown}
                                                                            values={fields?.value}
                                                                            disabled={false}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        />
                                                                        : (fields?.input == "radio") ?
                                                                            <>&nbsp;
                                                                                <Tooltip title={fields?.placeHolder}>
                                                                                    <span className="icon-question"></span>
                                                                                </Tooltip><br />
                                                                                {fields?.options && fields.options.map((item) => {
                                                                                    return <label class="fancy-radio">
                                                                                        <input type="radio" className={fields?.class}
                                                                                            value={item?.value}
                                                                                            name={fields?.name}
                                                                                            checked={(item?.value == fields?.value) ? "checked" : ""}
                                                                                            onChange={(e) => { CheckOrRadioChange(item?.value, index) }} /><span><i></i>{item.option}</span>
                                                                                    </label>
                                                                                })}
                                                                            </>
                                                                            : (fields?.input == "timepicker") ?
                                                                                <TimePicker onChange={(e, timeString) => {
                                                                                    console.log(timeString);
                                                                                    onChangeTime(e, timeString, index, fields?.name);
                                                                                }} value={dayjs(fields?.value, format)} format={format}
                                                                                    className="form-control" style={{ display: "flex" }} allowClear={false} />

                                                                                : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitPos(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("name");
                                                    }}
                                                >
                                                    Day
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Time
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Schedule
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                                Sorting No.
                                            </StyledTableCell> */}
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            posDatas && posDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.sch_day}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.start_time + "-" + row.end_time}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.schedule === 1
                                                            ? "ON"
                                                            : "OFF"}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="center">

                                                    </StyledTableCell> */}
                                                    <StyledTableCell align="">
                                                        <a href="javascript:void(0);" onClick={(e) => { editPos(row) }}><i className="icon-pencil text-info p-2"></i></a>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={posCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default POS;
