import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/horizon-full.svg";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import { DatePicker, Space } from 'antd';
import { imgaeUpload } from "../../services/api";
import {
    aboutusData,
    AddAboutus,
    aboutusById,
    UpdateAboutus,
    DeleteAboutus,
    aboutusSelector,
    clearLoadingDatas,
    clearAboutAddLoading,
    clearAboutUpdateLoading,
    clearAboutDeleteLoading,
    clearData,
    clearErrormsg
} from "../../store/reducer/aboutus";
import { API_STATUS } from "../../utils/constants";
import {
    aboutusFields
} from "../fields/aboutusform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL, DateFormat } from "../../services/config";
import dayjs from 'dayjs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function AboutusEdit() {
    const params = useParams();
    const [editID, setEditID] = useState(params?.id);
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { aboutusDataById, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(aboutusSelector);
    const resetForm = aboutusFields;
    const [formFields, setFormFields] = useState(aboutusFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState();
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [uploadfiles, setUploadFiles] = useState([]);
    const [editDatas, setEditDatas] = useState([]);
    const [deletedImgs, setdeletedImgs] = useState([]);

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };
    const EditorChange = (value, index) => {
        console.log(value);
        formFields[index].value = value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value)
        formFields[index].value = value;
        setFormFields([...formFields]);
    };



    const onChangeTime = (value, time, index, name) => {
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    const onDrop = useCallback(async (acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        setUploadFiles(...uploadfiles, acceptedFiles);
        let formdata = new FormData();
        formdata.append("file", acceptedFiles)
        // acceptedFiles.forEach((file) => {
        //     console.log(file);
        //     formdata.append("fileToUpload", file);
        //     setfilename(file.name)
        //     const reader = new FileReader()

        //     reader.onabort = () => console.log('file reading was aborted')
        //     reader.onerror = () => console.log('file reading has failed')
        //     reader.onload = () => {
        //         // Do whatever you want with the file contents
        //         const binaryStr = reader.result
        //         setfileUpload(binaryStr);
        //         setfilePath(file.path);
        //         setfileerror(false);
        //         //console.log(binaryStr)
        //     }
        //     reader.readAsDataURL(file)
        // })

        //const data = await imgaeUpload(formdata);
        //console.log("getCount--> ", data);
    }, [])

    const thumbs = files.map(file => (
        <div className="dropzone_thum_img" key={file.name}>
            <div className="dropzone_thumb">
                <img
                    src={file.preview}
                    className="dropzone_img"
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: false
    })


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{e.message}</span>
            ))}
        </div>
    ));


    const deleteImg = (imgs, index) => {
        let img_index = _.findIndex(formFields, { name: "image" })
        if (img_index >= 0) {
            let images = formFields[img_index].value;
            console.log(images, "hi")
            _.unset(images, index);
            images = images.filter(function (element) {
                return element !== undefined;
            });
            console.log(images);
            formFields[img_index].value = images;
            console.log(formFields)
            setFormFields([...formFields])
            setdeletedImgs([...deletedImgs, imgs]);
        }

    };

    const submitAboutus = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
                return false;
            }
            const datas = formFields.map((data) => {
                if (data?.name === "status") {
                    let val = data.value;
                    console.log(val)
                    data.value = val[0].id;
                }
                return arr[data?.name] = data.value;
            });
            arr.id = updateID;
            const postDatas = arr;
            console.log(postDatas, "postDatas")
            //return false;
            dispatch(UpdateAboutus({ postDatas, uploadfiles, updateID }));
            setshowAdd(false);
        } else {
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
            }
            setShowvalidator(true);
            console.log("test123");
        }
    };

    useEffect(() => {
        if (editID)
            dispatch(aboutusById({ editID }));
    }, [])
    useEffect(() => {
        console.log(formFields, "formFields")
        console.log(deletedImgs, "deletedImgs")
    }, [formFields, deletedImgs])

    useEffect(() => {
        if (editDatas && editDatas.length == 0 && aboutusDataById) {
            console.log(aboutusDataById, "productDataById")
            setEditDatas(aboutusDataById);
            const row = aboutusDataById;
            setUpdateID(row.id);
            let date_val = [];


            let chosen_data = []
            let data = formFields.map((data, index) => {
                //console.log(_.filter(statusDropdown, { id: row.status }));
                if (data.name === "name")
                    data.value = row.name;
                if (data.name === "content")
                    data.value = row.content;
                if (data.name === "status") {
                    let set_status = _.filter(statusDropdown, { id: row.status });
                    console.log(set_status[0]);
                    data.value = set_status;
                }
                if (data.name === "image") {
                    console.log(row.image, "imgs")
                    data.value = row.image;

                }
            });
        }
    }, [aboutusDataById])

    useEffect(() => {
        simpleValidator.current.showMessages()
        console.log(loading, "loading")
        if (updateloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearAboutUpdateLoading())
            navigate('/aboutus');
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            dispatch(clearErrormsg())

        }
    }, [updateloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Aboutus"
                    Breadcrumb={[{ name: "Aboutus" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Edit Aboutus</h2>
                                    </div>
                                    <div className="col-md-6 row">

                                    </div>
                                </div>
                            </div>
                            <div className={"body"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <div className={(fields?.input == "file" || fields?.input == "cke") ? "col-md-12" : "col-md-6"}>
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.name}
                                                                    required=""
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={statusDropdown}
                                                                            values={fields?.value}
                                                                            disabled={false}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        />
                                                                        : (fields?.input == "file") ?
                                                                            <section className="row container">
                                                                                <div className="col-md-8">
                                                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                                                        <input {...getInputProps()}
                                                                                            name={fields?.name} />
                                                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                    </div></div>
                                                                                <div className="col-md-12">
                                                                                    <div className="thumbcontainer">{(thumbs && thumbs.length > 0 ? thumbs : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                        <div className="dropzone_thumb">
                                                                                            <img
                                                                                                src={assestURL + fields?.value}
                                                                                                className="dropzone_img"
                                                                                            />
                                                                                        </div>
                                                                                    </div> : '')}</div>
                                                                                </div>
                                                                                {fileRejectionItems}
                                                                                <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                            </section>
                                                                            : (fields?.input == "rangepicker") ?
                                                                                <RangePicker onChange={(time, timeString) => {
                                                                                    console.log(timeString);
                                                                                    onChangeTime(time, timeString, index, fields?.name);
                                                                                }}
                                                                                    value={fields?.value}
                                                                                    format={DateFormat}
                                                                                    className="form-control antformrange" />
                                                                                : (fields?.input == "cke") ?
                                                                                    <>
                                                                                        <CKEditor
                                                                                            editor={ClassicEditor}
                                                                                            config={{
                                                                                                height: '200px',
                                                                                                toolbar: [
                                                                                                    "heading",
                                                                                                    "|",
                                                                                                    'fontfamily', 'fontsize', '|',
                                                                                                    'alignment', '|',
                                                                                                    "bold",
                                                                                                    "italic",
                                                                                                    "link",
                                                                                                    "bulletedList",
                                                                                                    "numberedList",
                                                                                                    "blockQuote",
                                                                                                    "|",
                                                                                                    'sourceEditing', '|',
                                                                                                    "mediaEmbed",
                                                                                                    "insertTable",
                                                                                                    "tableColumn",
                                                                                                    "tableRow",
                                                                                                    "mergeTableCells",
                                                                                                    "|",
                                                                                                    "undo",
                                                                                                    "redo"
                                                                                                ],
                                                                                            }

                                                                                            }
                                                                                            data={fields?.value}
                                                                                            onReady={editor => {
                                                                                                // You can store the "editor" and use when it is needed.
                                                                                                console.log('Editor is ready to use!', editor);
                                                                                            }}
                                                                                            onChange={(event, editor) => {
                                                                                                const data = editor.getData();
                                                                                                console.log({ event, editor, data });
                                                                                                EditorChange(data, index)
                                                                                            }}
                                                                                            onBlur={(event, editor) => {
                                                                                                console.log('Blur.', editor);
                                                                                            }}
                                                                                            onFocus={(event, editor) => {
                                                                                                console.log('Focus.', editor);
                                                                                            }}
                                                                                        /></>
                                                                                    : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitAboutus(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutusEdit;
