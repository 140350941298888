import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateSorting } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "sorting";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    categoryDatas: null,
    categoryCount: 0
};

export const sortingUpdate = createAsyncThunk(
    `${namespace}/sortingUpdate`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await updateSorting({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const sortingSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        }
    },
    extraReducers: {
        [sortingUpdate.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [sortingUpdate.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
            // state.categoryCount = payload?.data?.count;
            // state.categoryDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
        },
        [sortingUpdate.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert();
            }
        },
    },
});

export const { clearData } = sortingSlice.actions;

export const sortingSelector = (state) => state.sorting;

export default sortingSlice.reducer;
