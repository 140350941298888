import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { contactusList, contactusUpdate, contactusListById } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "contactus";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    contactusDatas: null,
    contactusCount: 0,
    contactusDataById: null
};

export const contactusData = createAsyncThunk(
    `${namespace}/contactusData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await contactusList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateContactus = createAsyncThunk(
    `${namespace}/UpdateContactus`,
    async ({ postDatas, uploadfiles, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            console.log(postDatas, "interdata");
            let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const postData = new FormData();
            if (uploadfiles) {
                uploadfiles.forEach((file) => {
                    console.log(file);
                    postData.append("uploadedFile", file);
                })
            } else {
                postData.append("uploadedFile", []);
            }
            postData.append("payload", payload);
            const data = await contactusUpdate(postData, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const contactusById = createAsyncThunk(
    `${namespace}/contactusById`,
    async ({ editID }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await contactusListById(editID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const contactusSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearContactLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearContactAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearContactUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearContactDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [contactusData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [contactusData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.contactusCount = payload?.data?.count;
            // state.contactusDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count, "payload")
            state.contactusCount = payloaddatas?.count;
            state.contactusDatas = payloaddatas?.data;
        },
        [contactusData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert();
            }
            state.errorMessage = action?.payload?.data;
        },
        [UpdateContactus.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateContactus.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateContactus.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [contactusById.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [contactusById.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas, "payload")
            state.contactusDataById = payloaddatas?.response;
        },
        [contactusById.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        }
    },
});

export const { clearData, clearLoadingDatas, clearContactAddLoading, clearContactUpdateLoading, clearContactDeleteLoading, clearErrormsg } = contactusSlice.actions;

export const contactusSelector = (state) => state.contactus;

export default contactusSlice.reducer;
