import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import loginReducer from "./reducer/login";
import categoryReducer from "./reducer/category";
import subcategoryReducer from "./reducer/subcategory";
import itemsizeReducer from "./reducer/itemsize";
import productsReducer from "./reducer/products";
import customersReducer from "./reducer/customers";
import sortingReducer from "./reducer/sorting";
import custommenuReducer from "./reducer/custommenu";
import custommenuitemReducer from "./reducer/custommenuitem";
import posReducer from "./reducer/pos";
import bannerReducer from "./reducer/banner";
import notificationReducer from "./reducer/notification";
import aboutusReducer from "./reducer/aboutus";
import contactusReducer from "./reducer/contactus";
import giftcardReducer from "./reducer/giftcard";
import kitchensectionReducer from "./reducer/kitchensection";
import kitchencodeReducer from "./reducer/kitchencode";
import generalReducer from "./reducer/general";

export const reducer = {
  login: loginReducer,
  category: categoryReducer,
  subcategory: subcategoryReducer,
  itemsize: itemsizeReducer,
  products: productsReducer,
  customers: customersReducer,
  sorting: sortingReducer,
  custommenu: custommenuReducer,
  custommenuitem: custommenuitemReducer,
  pos: posReducer,
  banner: bannerReducer,
  notification: notificationReducer,
  aboutus: aboutusReducer,
  contactus: contactusReducer,
  giftcard: giftcardReducer,
  kitchensection: kitchensectionReducer,
  kitchencode: kitchencodeReducer,
  general: generalReducer
};

const store = configureStore({
  reducer,
  // middleware: [ // Because we define the middleware property here, we need to explictly add the defaults back in.
  //     ...getDefaultMiddleware(),
  // ]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
