import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../view/pageheader";
import {
    doLogin,
    loginSelector,
} from "../store/reducer/login";
import { API_STATUS } from "../utils/constants";

function Dashboard() {
    console.log("hele")
    const dispatch = useDispatch();
    let navigate = useNavigate();

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Dashboard"
                    Breadcrumb={[{ name: "Dashboard" }]}
                />
                <div className="row clearfix">
                    <div className="card" style={{"height": "400px", "padding": "25%"}}>
                        <h3 className="text-center">Welcome to Dipmenu</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
