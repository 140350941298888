import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import RoutesLink from '../components/routes';
import NavBar from "./navbar";

const mapStateToProps = (state) => ({

});
function Full (){

    return (
            <div className="theme-cyan">
                <div id="main-wrapper">
                    <NavBar />
                    <div id="main-content">
                        <RoutesLink></RoutesLink>
                    </div>
                    {/* <footer className="footer d-flex justify-content-center">
                        2022© Horizon Managed Services
                        <span className="ms-2" style={{ color: "black" }}>Privacy · Terms of Use </span>
                    </footer> */}
                </div>
            </div>
    );
};

export default Full;
