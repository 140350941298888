import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { itemsizeList, itemsizeAdd, itemsizeUpdate, itemsizeDelete } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "itemsize";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    itemsizeDatas: null,
    itemsizeCount: 0
};

export const itemsizeData = createAsyncThunk(
    `${namespace}/itemsizeData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="", status="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await itemsizeList(query, page, limit, sortby, order, status);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddItemsize = createAsyncThunk(
    `${namespace}/AddItemsize`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await itemsizeAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateItemsize = createAsyncThunk(
    `${namespace}/UpdateItemsize`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await itemsizeUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteItemsize = createAsyncThunk(
    `${namespace}/DeleteItemsize`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await itemsizeDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const itemsizeSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearItemLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading ="initial";
            state.deleteloading ="initial";
        },
        clearItemAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearItemUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearItemDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [itemsizeData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [itemsizeData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.categoryCount = payload?.data?.count;
            // state.categoryDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.count,"payload")
            state.itemsizeCount = payloaddatas?.count;
            state.itemsizeDatas = payloaddatas?.data;
        },
        [itemsizeData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert();
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddItemsize.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddItemsize.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddItemsize.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateItemsize.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateItemsize.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateItemsize.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteItemsize.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteItemsize.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteItemsize.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearItemLoadingDatas, clearItemAddLoading, clearItemUpdateLoading, clearItemDeleteLoading, clearErrormsg } = itemsizeSlice.actions;

export const itemsizeSelector = (state) => state.itemsize;

export default itemsizeSlice.reducer;
