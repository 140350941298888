import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import Login from "./components/login";
import PrivateRoute from './PrivateRoute';
import Full from './view/full'
import RoutesLink from './components/routes';

import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "./components/dashboard";
import Category from "./components/category";
import SubCategory from "./components/subcategory";
import ItemSize from "./components/itemsize";
import Products from "./components/products/products";
import AddProducts from "./components/products/addproducts";
import EditProducts from "./components/products/editproducts";
import Customers from "./components/customers/customers";
import Sorting from "./components/sorting/sorting";
import ForgotPassword from "./components/forgotpassword";
import ResetPassword from "./components/resetpassword";
import Custommenu from "./components/custommenu";
import CustomMenuItem from "./components/custommenuitem";
import POS from "./components/settings/pos";
import Banner from "./components/settings/banner";
import AddBanners from "./components/settings/addbanner";
import EditBanners from "./components/settings/editbanner";
import Notification from "./components/settings/notification";
import Aboutus from "./components/settings/aboutus";
import AboutusAdd from "./components/settings/addaboutus";
import AboutusEdit from "./components/settings/editaboutus";
import Contactus from "./components/settings/contactus";
import ContactusEdit from "./components/settings/editcontactus";
import GiftCard from "./components/giftcard";
import Kitchensection from "./components/kitchensection";
import Kitchencode from "./components/kitchencode";
import General from "./components/settings/general";

function App() {
  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/resetpassword" element={<ResetPassword />} />
          <Route path="/" element={<PrivateRoute />} >
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/category" element={<Category />} />
            <Route exact path="/subcategory" element={<SubCategory />} />
            <Route exact path="/itemsize" element={<ItemSize />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/addproducts" element={<AddProducts />} />
            <Route exact path="/editproducts/:id" element={<EditProducts />} />
            <Route exact path="/customers" element={<Customers />} />
            <Route exact path="/sorting" element={<Sorting />} />
            <Route exact path="/custommenu" element={<Custommenu />} />
            <Route exact path="/custommenuitem" element={<CustomMenuItem />} />
            <Route exact path="/pos" element={<POS />} />
            <Route exact path="/banner" element={<Banner />} />
            <Route exact path="/addbanner" element={<AddBanners />} />
            <Route exact path="/editbanner/:id" element={<EditBanners />} />
            <Route exact path="/notification" element={<Notification />} />
            <Route exact path="/aboutus" element={<Aboutus />} />
            <Route exact path="/addaboutus" element={<AboutusAdd />} />
            <Route exact path="/editaboutus/:id" element={<AboutusEdit />} />     
            <Route exact path="/contactus" element={<Contactus />} />
            <Route exact path="/editcontactus/:id" element={<ContactusEdit />} />     
            <Route exact path="/giftcard" element={<GiftCard />} />    
            <Route exact path="/kitchensection" element={<Kitchensection />} />  
            <Route exact path="/kitchencode" element={<Kitchencode />} />   
            <Route exact path="/general" element={<General />} />  
          </Route>
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;
