import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notificationList, notificationAdd, notificationUpdate, notificationDelete } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "notification";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    notificationDatas: null,
    notificationCount: 0
};

export const notificationData = createAsyncThunk(
    `${namespace}/notificationData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await notificationList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddNotification = createAsyncThunk(
    `${namespace}/AddNotification`,
    async ({ postDatas, uploadfiles }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postDatas, "interdata");
            let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const postData = new FormData();
            uploadfiles.forEach((file) => {
                console.log(file);
                postData.append("uploadedFile", file);
            })
            postData.append("payload", payload);
            const data = await notificationAdd(postData);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateNotification = createAsyncThunk(
    `${namespace}/UpdateNotification`,
    async ({ postDatas, uploadfiles, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const postData = new FormData();
            uploadfiles.forEach((file) => {
                console.log(file);
                postData.append("uploadedFile", file);
            })
            postData.append("payload", payload);
            const data = await notificationUpdate(postData, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteNotification = createAsyncThunk(
    `${namespace}/DeleteNotification`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await notificationDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const notificationSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearNotiLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading ="initial";
            state.deleteloading ="initial";
        },
        clearNotiAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearNotiUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearNotiDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [notificationData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [notificationData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.notificationCount = payload?.data?.count;
            // state.notificationDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count,"payload")
            state.notificationCount = payloaddatas?.count;
            state.notificationDatas = payloaddatas?.data;
        },
        [notificationData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert();
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddNotification.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddNotification.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddNotification.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateNotification.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateNotification.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateNotification.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteNotification.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteNotification.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteNotification.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, clearNotiAddLoading, clearNotiUpdateLoading, clearNotiDeleteLoading, clearErrormsg } = notificationSlice.actions;

export const notificationSelector = (state) => state.notification;

export default notificationSlice.reducer;
