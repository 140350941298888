import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { custommenuitemList, custommenuitemAdd, custommenuitemUpdate, custommenuitemDelete, custommenuitemFilter } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "custommenuitem";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    custommenuitemDatas: null,
    custommenuitemCount: 0
};

export const custommenuitemData = createAsyncThunk(
    `${namespace}/custommenuitemData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await custommenuitemList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const custommenuitemFilterData = createAsyncThunk(
    `${namespace}/custommenuitemFilterData`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await custommenuitemFilter({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const Addcustommenuitem = createAsyncThunk(
    `${namespace}/Addcustommenuitem`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await custommenuitemAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const Updatecustommenuitem = createAsyncThunk(
    `${namespace}/Updatecustommenuitem`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await custommenuitemUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const Deletecustommenuitem = createAsyncThunk(
    `${namespace}/Deletecustommenuitem`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await custommenuitemDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const custommenuitemSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearsCMILoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearsCMIAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearsCMIUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearsCMIDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [custommenuitemData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [custommenuitemData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            //console.log(payloaddatas,"payload")
            state.custommenuitemCount = payloaddatas?.count;
            state.custommenuitemDatas = payloaddatas?.data;
        },
        [custommenuitemData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert();
            }
        },
        [Addcustommenuitem.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [Addcustommenuitem.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [Addcustommenuitem.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [Updatecustommenuitem.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [Updatecustommenuitem.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [Updatecustommenuitem.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [Deletecustommenuitem.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [Deletecustommenuitem.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [Deletecustommenuitem.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [custommenuitemFilterData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [custommenuitemFilterData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data, "payload")
            state.custommenuitemCount = payloaddatas?.count;
            state.custommenuitemDatas = payloaddatas?.data;
        },
        [custommenuitemFilterData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearsLoadingDatas, clearsCMIAddLoading, clearsCMIUpdateLoading, clearsCMIDeleteLoading, clearErrormsg } = custommenuitemSlice.actions;

export const custommenuitemSelector = (state) => state.custommenuitem;

export default custommenuitemSlice.reducer;
