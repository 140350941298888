import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { giftcardList, giftcardAdd, giftcardUpdate, giftcardDelete } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "giftcard";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    giftcardDatas: null,
    giftcardCount: 0
};

export const giftcardData = createAsyncThunk(
    `${namespace}/giftcardData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await giftcardList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddGiftCard = createAsyncThunk(
    `${namespace}/AddGiftCard`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await giftcardAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateGiftCard = createAsyncThunk(
    `${namespace}/UpdateGiftCard`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await giftcardUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteGiftCard = createAsyncThunk(
    `${namespace}/DeleteGiftCard`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await giftcardDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const giftcardSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearGiftLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading ="initial";
            state.deleteloading ="initial";
        },
        clearGiftAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearGiftUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearGiftDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [giftcardData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [giftcardData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.giftcardCount = payload?.data?.count;
            // state.giftcardDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count,"payload")
            state.giftcardCount = payloaddatas?.count;
            state.giftcardDatas = payloaddatas?.data;
        },
        [giftcardData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert();
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddGiftCard.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddGiftCard.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddGiftCard.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateGiftCard.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateGiftCard.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateGiftCard.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteGiftCard.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteGiftCard.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteGiftCard.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, clearGiftAddLoading, clearGiftUpdateLoading, clearGiftDeleteLoading, clearErrormsg } = giftcardSlice.actions;

export const giftcardSelector = (state) => state.giftcard;

export default giftcardSlice.reducer;
